import { IconType } from "react-icons";

export interface HeroItemStats {
  LogoUrl: string;
  Name: string;
  Symbol: string;
  Price: string;
  MarketCap: string;
  CircSupply: string;
  PriceChange: {
    Hour: { ChangeString: string; Color: string };
    Day: { ChangeString: string; Color: string };
    Week: { ChangeString: string; Color: string };
  };
  MarketCapNumber: number;
  isNew?: boolean;
}

export interface WhitelistTokenData {
  Address: string;
  ImageURL: string;
  Name: string;
  Symbol: string;
  isNew?: boolean;
  Details: {
    Description: string;
    OfficialLinks: OfficialLinksInterface;
    SocialLinks: SocialLinksInterface;
    Markets: MarketsInterface[];
  };
}

export interface OfficialLinksInterface {
  website?: string | undefined;
  tokenPage?: string | undefined;
  github?: string | undefined;
}

export interface SocialLinksInterface {
  xTwitter?: string | undefined;
  discord?: string | undefined;
  reddit?: string | undefined;
}

export interface MarketsInterface {
  exchange: string;
  assets: string[];
  type: string;
  address: string;
}

/// AssetPage Interfaces
interface KeyComponents {
  textButton: string;
  icon: IconType;
}

export interface ButtonKeys {
  [key: string]: KeyComponents;
}

interface MarketComponents {
  image: string;
  url: string;
}

export interface MarketKeys {
  [key: string]: MarketComponents;
}

export interface AssetPriceRangeItem {
  price_usd: number;
  timestamp: number;
}

// HeroItemStats constants
export const loadingItem: HeroItemStats = {
  LogoUrl: "loader.gif",
  Name: "Loading Asset ...",
  Symbol: "",
  Price: "",
  MarketCap: "",
  CircSupply: "",
  PriceChange: {
    Hour: { ChangeString: "", Color: "" },
    Day: { ChangeString: "", Color: "" },
    Week: { ChangeString: "", Color: "" },
  },
  MarketCapNumber: 0,
  isNew: false,
};

export const errorItem: HeroItemStats = {
  LogoUrl: "loader.gif",
  Name: "There was a problem fetching the Asset data",
  Symbol: "",
  Price: "",
  MarketCap: "",
  CircSupply: "",
  PriceChange: {
    Hour: { ChangeString: "", Color: "" },
    Day: { ChangeString: "", Color: "" },
    Week: { ChangeString: "", Color: "" },
  },
  MarketCapNumber: 0,
  isNew: false,
};

export const errorWhitelistToken: WhitelistTokenData = {
  Address: "Error",
  ImageURL: "loader.gif",
  Name: "Error",
  Symbol: "Error",
  Details: {
    Description: "",
    OfficialLinks: {
      website: "",
      tokenPage: "",
      github: "",
    },
    SocialLinks: {
      xTwitter: "",
      discord: "",
      reddit: "",
    },
    Markets: [
      {
        exchange: "",
        assets: [""],
        type: "",
        address: "",
      },
    ],
  },
};

export const loadingCharto: AssetPriceRangeItem[] = [
  {
    timestamp: 283,
    price_usd: 4001.56,
  },
  {
    timestamp: 284,
    price_usd: 3000,
  },
  {
    timestamp: 285,
    price_usd: 2000,
  },
  {
    timestamp: 286,
    price_usd: 2780,
  },
  {
    timestamp: 287,
    price_usd: 1890,
  },
  {
    timestamp: 288,
    price_usd: 2390,
  },
  {
    timestamp: 289,
    price_usd: 3490,
  },
];

export const loadingChart: AssetPriceRangeItem[] = [
  {
    timestamp: 0,
    price_usd: 0,
  },
];
