import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  useClipboard,
  Alert,
  AlertIcon,
  HStack,
} from "@chakra-ui/react";
import { RxCopy } from "react-icons/rx";

export default function Donations() {
  const shimmerL1Address = `smr1qr2qrvgs9khussufu9szzce548zjglphge66qq33xwkq6l06d3r7kc0sxl0`;
  const shimmerL2Address = `0xA2E4e36b3CC669217609fcce2Ad6Fe6835A5e6ae`;

  const { hasCopied: hasCopiedShimmerL1, onCopy: onCopyShimmerL1 } =
    useClipboard(shimmerL1Address);
  const { hasCopied: hasCopiedShimmerL2, onCopy: onCopyShimmerL2 } =
    useClipboard(shimmerL2Address);

  return (
    <Box bg="gray.800">
      <Container maxW="container.xl">
        <Stack
          as={Box}
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 40 }}
        >
          <Heading
            color="gray.100"
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight="110%"
          >
            Donations
            <br />
            <Text as="span" color="#1bb18c">
              Page
            </Text>
          </Heading>

          <Text
            fontSize="2xl"
            color={"gray.100"}
            align={"left"}
            maxW="800px"
            alignSelf={"center"}
          >
            User donations are important for this project since they are the
            only funding that{" "}
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            has, if you want this project to continue and improve feel free to
            donate at the following addresses:
          </Text>
          <Box alignSelf={"center"} style={{ whiteSpace: "pre-wrap" }}>
            <Text
              fontSize="2xl"
              color={"gray.100"}
              align={"left"}
              maxW="800px"
              alignSelf={"center"}
            >
              <i>
                Shimmer Mainnet L1 Address:
                <br></br>
                <HStack>
                  <Text
                    style={{
                      color: "#1bb18c",
                      fontSize: "18px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {shimmerL1Address}
                  </Text>
                  <RxCopy
                    size={"22"}
                    color="white"
                    cursor={"pointer"}
                    onClick={onCopyShimmerL1}
                  />
                </HStack>
                {hasCopiedShimmerL1 && (
                  <Alert backgroundColor={"transparent"} textColor={["white"]}>
                    <AlertIcon />
                    Copied!
                  </Alert>
                )}
                <br />
                Shimmer Mainnet L2 EVM Address:
                <br></br>
                <HStack>
                  <Text style={{ color: "#1bb18c", fontSize: "23px" }}>
                    {shimmerL2Address}
                  </Text>
                  <RxCopy
                    size={"22"}
                    color="white"
                    cursor={"pointer"}
                    onClick={onCopyShimmerL2}
                  />
                </HStack>
                {hasCopiedShimmerL2 && (
                  <Alert backgroundColor={"transparent"} textColor={["white"]}>
                    <AlertIcon />
                    Copied!
                  </Alert>
                )}
              </i>
            </Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
