import {
  Box,
  Image,
  HStack,
  Container,
  Text,
  GridItem,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TbExternalLink } from "react-icons/tb";
import { FaBook } from "react-icons/fa";
import { whitelistTokenData } from "../constants";
import { errorWhitelistToken } from "../types";
import { GetItemData } from "./core/ItemData";
import {
  addMarketsBlock,
  addOfficialLinksBlock,
  addSocialBlock,
} from "./partials/AssetPagePartials";
import PriceAssetChart from "./partials/PriceCharts";

const explorerUrl = `https://explorer.evm.shimmer.network/address/`;

export default function AssetPage() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const gridTemplateAreas = isMobile
    ? `"header"
     "main-data"
     "graph"
     "general-data"
     "description"
     "pool-list"`
    : `"header header"
     "main-data graph"
     "general-data graph"
     "general-data description"
     "general-data pool-list"`;

  const { assetSymbol } = useParams<{ assetSymbol: string }>();

  const tokenWhitelistObject =
    whitelistTokenData.find((token) => token.Symbol === assetSymbol) ||
    errorWhitelistToken;

  const tokenDBObject = GetItemData(tokenWhitelistObject);

  return (
    <Box bg="gray.800">
      <Container maxW="container.xl">
        <Grid
          templateAreas={gridTemplateAreas}
          gridTemplateRows={"50px"}
          gridTemplateColumns={"400px 1fr"}
          h="auto"
          gap="1"
          color="blackAlpha.700"
          fontWeight="bold"
        >
          <GridItem pl="3" marginBlockStart="6" area={"header"}>
            <HStack spacing="14px">
              <Text textColor={"white"}>{`Assets`}</Text>
              <Text textColor={"white"}>{`>`}</Text>
              <Text
                textColor={"white"}
              >{`${tokenWhitelistObject.Symbol}`}</Text>
            </HStack>
          </GridItem>

          <GridItem pl="6" py="6" bg="gray.900" area={"main-data"}>
            <HStack spacing="13px">
              <Box>
                <Image
                  src={tokenWhitelistObject.ImageURL}
                  boxSize="2.8rem"
                  mr="20px"
                />
              </Box>
              <Box fontSize={"3xl"} fontWeight={"normal"} textColor={"white"}>
                {tokenWhitelistObject.Name}
              </Box>
              <Text fontSize={"xl"} textColor={"white"}>
                /
              </Text>
              <Box
                fontSize={"xl"}
                fontWeight={"bold"}
                textColor={"white"}
                marginEnd={5}
              >
                <i>{tokenWhitelistObject.Symbol}</i>
              </Box>
            </HStack>
            <HStack
              spacing={tokenDBObject.Price.length < 13 ? "8px" : "0"}
              pl={tokenDBObject.Price.length < 17 ? "12" : "0"}
            >
              <Box
                fontSize={tokenDBObject.Price.length < 11 ? "3xl" : "2xl"}
                textColor={"white"}
              >
                {tokenDBObject.Price}
              </Box>
              <Box pl="3"></Box>
              <Box
                fontSize={"l"}
                textColor={tokenDBObject.PriceChange.Day.Color}
              >
                {tokenDBObject.PriceChange.Day.ChangeString}
              </Box>
              <Box textColor={"white"} fontSize={"sm"}>
                {tokenDBObject.Price.length < 13 ? "(1d)" : ""}
              </Box>
            </HStack>
          </GridItem>

          <GridItem pl="7" py="6" bg="gray.900" area={"general-data"}>
            <HStack>
              <Box textColor={"white"} fontSize={"lg"} fontWeight="normal">
                MarketCap
              </Box>
              <Box
                textColor={"white"}
                fontSize={"lg"}
                fontWeight="bold"
                ml="auto"
                marginEnd={"9"}
              >
                {`$ ` +
                  tokenDBObject.MarketCapNumber.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </Box>
            </HStack>
            <HStack>
              <Box textColor={"white"} fontSize={"lg"} fontWeight="normal">
                Circulating Supply
              </Box>
              <Box
                textColor={"white"}
                fontSize={"lg"}
                fontWeight="bold"
                ml="auto"
                marginEnd={"9"}
              >
                {tokenDBObject.CircSupply}
              </Box>
            </HStack>

            <HStack fontSize={"md"} marginBlockStart={"10"}>
              <Box textColor={"white"} fontWeight="normal">
                Contract:
              </Box>
              <Box textColor={"white"} fontWeight="thin">
                (ERC20)
              </Box>
            </HStack>
            <HStack alignItems="center">
              <Box textColor={"white"} fontSize={"13"} fontWeight="thin">
                {tokenWhitelistObject.Address}
              </Box>
              <TbExternalLink
                size={"22"}
                color="white"
                cursor={"pointer"}
                onClick={() => {
                  window.open(
                    explorerUrl + `${tokenWhitelistObject.Address}`,
                    "_blank"
                  );
                }}
              />
            </HStack>

            {addOfficialLinksBlock(tokenWhitelistObject.Details?.OfficialLinks)}

            {addSocialBlock(tokenWhitelistObject.Details?.SocialLinks)}
          </GridItem>

          <GridItem area={"graph"} display="flex" justifyContent="center">
            {PriceAssetChart(tokenWhitelistObject.Address)}
          </GridItem>

          <GridItem pl="6" py="6" bg="gray.900" area={"description"}>
            <HStack spacing="14px">
              <FaBook color="white" />
              <Box fontSize={"xl"} fontWeight={"bold"} textColor={"white"}>
                Token Description:
              </Box>
            </HStack>
            <Box
              fontSize={"xl"}
              fontWeight={"thin"}
              textColor={"white"}
              marginInlineStart={"8"}
              marginInlineEnd={"10"}
              dangerouslySetInnerHTML={{
                __html: tokenWhitelistObject.Details?.Description,
              }}
            ></Box>
          </GridItem>

          <GridItem pl="6" py="6" bg="gray.900" area={"pool-list"}>
            {addMarketsBlock(
              tokenWhitelistObject.Name,
              tokenWhitelistObject.Symbol,
              tokenWhitelistObject.Details?.Markets
            )}
          </GridItem>
        </Grid>
      </Container>
      <Box py="2"></Box>
    </Box>
  );
}
