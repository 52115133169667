import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaMedium, FaSquareXTwitter } from "react-icons/fa6";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Box>
        <Container
          background={"gray.900"}
          as={Stack}
          maxW={"inherent"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text color={"gray.300"}>© 2024 ShimmerCap. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <Box
              cursor={"pointer"}
              onClick={() => {
                window.open("https://twitter.com/ShimmerCap", "_blank");
              }}
            >
              <SocialButton label={"XTwitter"} href={""}>
                <FaSquareXTwitter color="white" />
              </SocialButton>
            </Box>

            <Box
              cursor={"pointer"}
              onClick={() => {
                window.open(
                  "https://medium.com/@gustavobelbruno/introducing-shimmercap-2c12a3c5f541",
                  "_blank"
                );
              }}
            >
              <SocialButton label={"Medium"} href={""}>
                <FaMedium color="white" />
              </SocialButton>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
