import {
  Box,
  Tr,
  Td,
  Text,
  Image,
  HStack,
  TableContainer,
  Stack,
  Heading,
  Table,
  Thead,
  Th,
  Tbody,
  Link,
} from "@chakra-ui/react";
import { HeroItemStats } from "../../types";
import { whitelistTokenData } from "../../constants";
import GetSortedItemsData from "../core/ItemData";

let bg_rb_black = "black"; // Hover color Item
let bg_rb_gray = "gray.900"; // Hover color Item

export function getGeneratedHeroTable() {
  const itemsData = GetSortedItemsData();

  let rowsBody = [];
  for (let i = 0; i < whitelistTokenData.length; i++) {
    rowsBody.push(ItemBody(i + 1, itemsData[i]));
  }

  return (
    <TableContainer background="gray.800">
      <Stack as={Box} textAlign={"center"} py={{ base: 3, md: 3 }}>
        <Heading
          color={"gray.100"}
          fontSize={"2xl"}
          transition="transform 250ms"
          _hover={{
            transform: "scale(1.02)",
          }}
        >
          Market Prices by Market Cap
        </Heading>
      </Stack>
      <Table
        variant="striped"
        background="gray.800"
        colorScheme="whiteAlpha"
        textColor={"whiteAlpha.800"}
        h="full"
      >
        <Thead>
          <Tr
            _hover={{
              bg: bg_rb_gray,
            }}
          >
            <Th paddingLeft={12}>#</Th>
            <Th>Name</Th>
            <Th textAlign={"right"}>Price (in USD)</Th>
            <Th>1h %</Th>
            <Th>24h %</Th>
            <Th>7d %</Th>
            <Th textAlign={"right"}>MarketCap</Th>
            <Th paddingRight={12} textAlign={"right"}>
              Circul Supply
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rowsBody}</Tbody>
      </Table>
      <Text
        background="gray.800"
        color={"gray.100"}
        padding={"4"}
        py={{ base: 3, md: 3 }}
        h="70"
      ></Text>
    </TableContainer>
  );
}

function ItemBody(itemIndex: number, itemDataStruct: HeroItemStats) {
  return (
    <Tr
      cursor="pointer"
      _hover={{
        bg: bg_rb_black,
      }}
      onClick={() => {
        if (String(itemDataStruct.LogoUrl) != "loader.gif")
          window.location.href = `/asset/${itemDataStruct.Symbol}`;
      }}
    >
      <Td paddingLeft={12}>{itemIndex}</Td>
      <Td>
        <HStack spacing="13px">
          <Image
            src={String(itemDataStruct.LogoUrl)}
            boxSize="2rem"
            objectFit="cover"
            marginEnd={3}
          />

          <Box fontSize={"lg"} fontWeight={"normal"} marginEnd={1}>
            {itemDataStruct.Name}
          </Box>
          <Text fontSize={"xs"}>/</Text>
          <Box fontSize={"sm"} fontWeight={"bold"} marginStart={1}>
            <i> {itemDataStruct.Symbol}</i>
          </Box>
          {AddIsNewIcon(itemDataStruct.isNew)}
        </HStack>
      </Td>
      <Td textAlign={"right"} textColor={"gray.100"}>
        <b>{itemDataStruct.Price}</b>
      </Td>
      <Td textColor={itemDataStruct.PriceChange.Hour.Color}>
        {itemDataStruct.PriceChange.Hour.ChangeString}
      </Td>
      <Td textColor={itemDataStruct.PriceChange.Day.Color}>
        {itemDataStruct.PriceChange.Day.ChangeString}
      </Td>
      <Td textColor={itemDataStruct.PriceChange.Week.Color}>
        {itemDataStruct.PriceChange.Week.ChangeString}
      </Td>
      <Td textAlign={"right"}>{itemDataStruct.MarketCap}</Td>
      <Td paddingRight={12} textAlign={"right"}>
        {itemDataStruct.CircSupply}
      </Td>
    </Tr>
  );
}

function AddIsNewIcon(isNew: boolean | undefined) {
  if (isNew) {
    return (
      <Box marginStart={1}>
        <Image src={"new-icon-orange.svg"} boxSize="2rem" mr="12px" />
      </Box>
    );
  }
}
