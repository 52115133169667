import { shimmercapApiUrl } from "../constants";
import axios from "axios";
import { HeroItemStats, WhitelistTokenData, errorItem } from "../types";
import { formatNumber, getPriceChangeString, pricePrettier } from "./utils";

export default async function getDbDataFetcher(
  whitelistToken: WhitelistTokenData
) {
  const assetAddress = whitelistToken.Address;
  const requestUrl = `${shimmercapApiUrl}asset_basic_stats_db?address=${assetAddress}`;
  try {
    const apiResponse = await axios.get(requestUrl);
    const price = Number(apiResponse.data[assetAddress].price.usd);
    const marketCap = Number(apiResponse.data[assetAddress].marketcap.usd);
    const circSupply = Number(
      apiResponse.data[assetAddress].circulating_supply
    );

    const oneHourChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_hour_change
    );
    const oneDayChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_day_change
    );
    const oneWeekChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_week_change
    );

    const newItem: HeroItemStats = {
      LogoUrl: whitelistToken.ImageURL,
      Name: whitelistToken.Name,
      Symbol: whitelistToken.Symbol,
      Price: `$ ${pricePrettier(price)}`,
      MarketCap: `$ ${formatNumber(marketCap)}`,
      CircSupply: `${formatNumber(circSupply)}`,
      PriceChange: {
        Hour: {
          ChangeString: oneHourChangeData[0],
          Color: oneHourChangeData[1],
        },
        Day: { ChangeString: oneDayChangeData[0], Color: oneDayChangeData[1] },
        Week: {
          ChangeString: oneWeekChangeData[0],
          Color: oneWeekChangeData[1],
        },
      },
      MarketCapNumber: marketCap,
      isNew: whitelistToken.isNew ? true : false,
    };

    return newItem;
  } catch (error) {
    return errorItem;
  }
}

export async function getPriceRangeDbDataFetcher(
  assetAddress: string,
  daysRange: number,
  interval: number
) {
  const requestUrl = `${shimmercapApiUrl}asset_price_range_db?address=${assetAddress}&days=${daysRange}&interval=${interval}`;
  try {
    const apiResponse = await axios.get(requestUrl);

    return apiResponse.data;
  } catch (error) {
    return [];
  }
}
