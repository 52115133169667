import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  HStack,
  Image,
  Divider,
  Center,
} from "@chakra-ui/react";

export default function About() {
  return (
    <Box bg="gray.800">
      <Container maxW="container.xl">
        <Stack as={Box} textAlign="center" spacing={{ base: 7, md: 10 }}>
          <Heading
            color="gray.100"
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight="110%"
            marginTop={"100px"}
          >
            About
            <br />
            <Text as="span" color="#1bb18c">
              Page
            </Text>
          </Heading>
          <Text
            fontSize="2xl"
            color="gray.100"
            align="left"
            maxW={{ base: "100%", md: "700px" }}
            alignSelf="center"
          >
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            was created and developed up to this point by{" "}
            <i>Gustavo Belbruno</i>.
          </Text>
          <Text
            fontSize="2xl"
            color="gray.100"
            align="left"
            maxW={{ base: "100%", md: "700px" }}
            alignSelf="center"
            marginBottom={"70px"}
          >
            For suggestions to improve{" "}
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            please contact:
            <span style={{ color: "#1bb18c" }}>
              {" "}
              <i>gustavobelbruno@gmail.com</i>
            </span>
          </Text>
        </Stack>
        <Center>
          <Divider maxWidth={"70%"} />
        </Center>
        <HStack marginTop={"20px"}>
          <Image src={`/bio_gus_pic.png`} maxWidth="30%" />
          <Box
            fontSize="2xl"
            color="gray.100"
            marginLeft={2}
            marginBottom={"70px"}
          >
            Introducing <i>Gustavo Belbruno</i>, the brain behind{" "}
            <i>
              <b>ShimmerCap</b>
            </i>
            . When I'm not defying the laws of physics to make the impossible
            possible, you'll probably find me enjoying a cup of coffee or
            plotting to conquer the world (in a good way, of course!).
            <br />
            <br />
            Behind every project, there is a story. Mine began when I discovered
            my fascination for science, research, and technology at an early
            age, coupled with a love for Music, Art, and creativity. These two
            seemingly disparate loves led me to a point in my life where I had
            to make a decision about which path to choose.
            <br />
            <br />
            I chose the more technical path, becoming an Engineer. However,
            following the wisdom of the great Leonardo, it would be a mistake to
            separate Engineering and innovation from Art and creativity.
            <br />
            <br />
            Adding to that, there is a devotion and admiration for Tools that
            help people use, understand, simplify, and enjoy the opportunities
            and capabilities of this vast and complicated world, this thinking
            is also inherited from another of my influences, Michael Faraday.
            <br />
            <br />
            It is on these foundations that all my projects are built,
            especially{" "}
            <i>
              <b>ShimmerCap</b>
            </i>
            , where I have focused on what matters most: <b>the User</b>. It is
            crucial for the user to receive unbiased, honest, and transparent
            information regarding their investments in the{" "}
            <i>Shimmer Network</i>. <br />
            The day this principle is compromised,{" "}
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            will lose its value.
          </Box>
        </HStack>
      </Container>
    </Box>
  );
}
