import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { customTheme } from "./customTheme";
import NavBar from "./pages/partials/NavBar";
import Footer from "./pages/partials/Footer";
import Hero from "./pages/Hero";
import Roadmap from "./pages/Roadmap";
import About from "./pages/About";
import WhatIs from "./pages/WhatIs";
import Donations from "./pages/Donations";
import AssetPage from "./pages/AssetPage";
import Launch from "./pages/Launch";
import ReactGA from "react-ga4";
import { useEffect } from "react";

ReactGA.initialize("G-XJXMBWB4ZM");

const AnalyticsWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view when route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  return null; // or any other component you may want to render
};

export const App = () => (
  <ChakraProvider theme={customTheme}>
    <BrowserRouter>
      <AnalyticsWrapper />
      <NavBar />
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/whatis" element={<WhatIs />}></Route>
        <Route path="/roadmap" element={<Roadmap />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/donations" element={<Donations />}></Route>
        <Route path="/asset/:assetSymbol" element={<AssetPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </ChakraProvider>
);
