export function pricePrettier(num: number) {
  if (num > 999) return num.toFixed(0);
  if (num > 99) return num.toFixed(1);
  if (num > 9) return num.toFixed(2);
  if (num > 0.1) return num.toFixed(4);
  if (num > 0.01) return num.toFixed(5);
  if (num > 0.001) return num.toFixed(6);
  if (num > 0.00001) return num.toFixed(8);
  if (num > 0.0000001) return num.toFixed(9);
  if (num > 0.000000001) return num.toFixed(10);
  if (num > 0.00000000001) return num.toFixed(12);
  if (num > 0.0000000000001) return num.toFixed(14);
  if (num > 0.00000000000001) return num.toFixed(15);
  else return num.toFixed(18);
}

export function formatNumber(num: number): string {
  const abbreviations: Record<string, number> = {
    T: 1e12, // Trillions
    B: 1e9, // Billions
    M: 1e6, // Millions
    K: 1e3, // Thousands
  };

  for (const key in abbreviations) {
    if (num >= abbreviations[key]) {
      const roundedNum: number = num / abbreviations[key];
      const formattedNum: number = parseFloat(roundedNum.toFixed(2));

      return formattedNum.toFixed(2) + key;
    }
  }
  return num.toFixed(2).toString();
}

export function getPriceChangeString(num: number): [string, string] {
  if (num == null) return ["-", "gray.500"];
  else {
    let symb;
    let sign;
    if (num > 0) {
      symb = "▲";
      sign = "green.500";
    } else if (num < 0) {
      symb = "▼";
      sign = "red.400";
    } else {
      symb = "=";
      sign = "gray.500";
    }
    return [`${symb} ${Math.abs(Number(num)).toFixed(2)}%`, sign];
  }
}
