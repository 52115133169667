import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";

const backgroundColor = "gray.900";

export const customTheme = extendTheme({
  styles: {
    global: (props: Record<string, any> | StyleFunctionProps) => ({
      "html, body": {
        background: mode(backgroundColor, backgroundColor)(props), //mode(light mode color, dark mode color)
      },
    }),
  },
});
