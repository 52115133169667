import { useEffect, useState } from "react";
import getDbDataFetcher, {
  getPriceRangeDbDataFetcher,
} from "../../core/RpcDataFetcher";
import { WhitelistTokenData, loadingChart, loadingItem } from "../../types";
import { whitelistTokenData } from "../../constants";

export default function GetSortedItemsData() {
  let itemsData = [];
  for (let i = 0; i < whitelistTokenData.length; i++) {
    itemsData.push(GetItemData(whitelistTokenData[i]));
  }

  // Sort By MarketCap
  itemsData.sort((a, b) => b.MarketCapNumber - a.MarketCapNumber);

  return itemsData;
}

export function GetItemData(whitelistToken: WhitelistTokenData) {
  const [itemDataStruct, setItemDataStruct] = useState(loadingItem);

  useEffect(() => {
    const fetchData = async () => {
      setItemDataStruct(await getDbDataFetcher(whitelistToken));
    };

    fetchData();
  }, []);

  return itemDataStruct;
}

// NOT USED
/*
export function GetPriceRangeData(
  assetAddress: string,
  daysRange: number,
  interval: number
) {
  const [priceRangeDataStruct, setPriceRangeDataStruct] =
    useState(loadingChart);

  useEffect(() => {
    const fetchData = async () => {
      setPriceRangeDataStruct(
        await getPriceRangeDbDataFetcher(assetAddress, daysRange, interval)
      );
    };

    fetchData();
  }, []);

  return priceRangeDataStruct;
}
*/
