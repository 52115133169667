import {
  Box,
  Text,
  HStack,
  Button,
  GridItem,
  Image,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  extendTheme,
} from "@chakra-ui/react";
import {
  ButtonKeys,
  MarketKeys,
  MarketsInterface,
  OfficialLinksInterface,
  SocialLinksInterface,
} from "../../types";
import {
  FaDiscord,
  FaGithub,
  FaReddit,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbExternalLink, TbWorld } from "react-icons/tb";
import { MdOutlineRequestPage } from "react-icons/md";
import { GiToken } from "react-icons/gi";

export function addSocialBlock(socialLinks: SocialLinksInterface) {
  return (
    <Box>
      <HStack fontSize={"md"} marginBlockStart={"7"}>
        <Box textColor={"white"} fontWeight="normal">
          Socials:
        </Box>
      </HStack>
      <HStack alignItems="center" spacing="8px" marginBlockStart={1}>
        {Object.keys(socialLinks).length > 0 ? (
          Object.entries(socialLinks).map(([key, value], _) =>
            addSocialButton(key, value)
          )
        ) : (
          <Text textColor={"white"} fontWeight="light" marginInlineStart={5}>
            No Social Links
          </Text>
        )}
      </HStack>
    </Box>
  );
}

function addSocialButton(key: string, urlLink: string) {
  const socialKeys: ButtonKeys = {
    xTwitter: { textButton: "XTwitter", icon: FaSquareXTwitter },
    discord: { textButton: "Discord", icon: FaDiscord },
    reddit: { textButton: "Reddit", icon: FaReddit },
  };

  return (
    <Button
      colorScheme={"blackAlpha"}
      bgColor={"gray.700"}
      size={"sm"}
      fontWeight={"bold"}
      textColor={"white"}
      leftIcon={socialKeys[key].icon({ color: "white" })}
      cursor={"pointer"}
      variant="solid"
      onClick={() => {
        window.open(urlLink, "_blank");
      }}
    >
      {socialKeys[key].textButton}
    </Button>
  );
}

export function addOfficialLinksBlock(officialLinks: OfficialLinksInterface) {
  return (
    <Box>
      <HStack fontSize={"md"} marginBlockStart={"7"}>
        <Box textColor={"white"} fontWeight="normal">
          Official Links:
        </Box>
      </HStack>
      <HStack alignItems="center" spacing="8px" marginBlockStart={1}>
        {Object.keys(officialLinks).length > 0 ? (
          Object.entries(officialLinks).map(([key, value], _) =>
            addOfficialLinkButton(key, value)
          )
        ) : (
          <Text textColor={"white"} fontWeight="light" marginInlineStart={5}>
            No Official Links
          </Text>
        )}
      </HStack>
    </Box>
  );
}

function addOfficialLinkButton(key: string, urlLink: string) {
  const officialLinkKeys: ButtonKeys = {
    website: { textButton: "Website", icon: TbWorld },
    tokenPage: { textButton: "Asset Page", icon: MdOutlineRequestPage },
    github: { textButton: "GitHub", icon: FaGithub },
  };

  return (
    <Button
      colorScheme={"blackAlpha"}
      bgColor={"gray.700"}
      size={"sm"}
      fontWeight={"bold"}
      textColor={"white"}
      leftIcon={officialLinkKeys[key].icon({ color: "white" })}
      cursor={"pointer"}
      variant="solid"
      onClick={() => {
        window.open(urlLink, "_blank");
      }}
    >
      {officialLinkKeys[key].textButton}
    </Button>
  );
}

export function addMarketsBlock(
  assetName: string,
  assetSymbol: string,
  markets: MarketsInterface[]
) {
  return (
    <Box>
      <HStack spacing="14px">
        <GiToken color="white" />
        <Box fontSize={"xl"} fontWeight={"bold"} textColor={"white"}>
          {assetName} Markets:
        </Box>
      </HStack>

      <TableContainer marginBlockStart={2}>
        <Table variant="simple" size="sm" colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th>Exchange</Th>
              <Th>Type</Th>
              <Th>Pair</Th>
              <Th>Liquidity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {markets.length > 0 ? (
              markets.map((market, _) => addMarketItem(assetSymbol, market))
            ) : (
              <Tr>
                <Text
                  textColor={"white"}
                  fontWeight="light"
                  marginInlineStart={5}
                  marginBlockStart={2}
                >
                  No Markets Info.
                </Text>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function addMarketItem(assetSymbol: string, market: MarketsInterface) {
  const marketsKeys: MarketKeys = {
    ShimmerSea: {
      image: "/marketsLogos/shimmersea.png",
      url: `https://shimmersea.finance/liquidity/pool/${market.address}`,
    },
    TangleSwap: {
      image: "/marketsLogos/tangleswap.jpg",
      url: `https://info.tangleswap.exchange/pools/${market.address}`,
    },
    IotaBee: {
      image: "/marketsLogos/iotabee.png",
      url: `https://iotabee.com/pool/all`,
    },
    Bitfinex: {
      image: "/marketsLogos/bitfinex.png",
      url: `https://trading.bitfinex.com/t/${assetSymbol}:UST?type=exchange`,
    },
  };
  return (
    <Tr>
      <Td>
        <HStack spacing="3px" alignItems="center">
          <Image
            src={marketsKeys[market.exchange].image}
            boxSize="7"
            mr="10px"
          />
          <Box fontSize={"l"} fontWeight={"semibold"} textColor={"white"}>
            {market.exchange}
          </Box>
        </HStack>
      </Td>
      <Td>
        <Box>
          <Box fontSize={"l"} fontWeight={"medium"} textColor={"white"}>
            {market.type}
          </Box>
        </Box>
      </Td>
      <Td>
        <Box>
          <HStack
            spacing="2px"
            alignItems="center"
            cursor={"pointer"}
            onClick={() => {
              window.open(marketsKeys[market.exchange].url || "", "_blank");
            }}
          >
            <Box fontSize={"l"} fontWeight={"semibold"} textColor={"white"}>
              {`${assetSymbol}/${market.assets}`}
            </Box>
            <TbExternalLink size={"25"} color="white" />
          </HStack>
        </Box>
      </Td>
      <Td>
        <Box>
          <Box fontSize={"l"} fontWeight={"medium"} textColor={"white"}>
            Very Soon...
          </Box>
        </Box>
      </Td>
    </Tr>
  );
}

export const theme = extendTheme({
  components: {
    Table: {
      variants: {
        simple: {
          th: {
            borderColor: "blue.700",
          },
          td: {
            borderColor: "blue.700",
          },
        },
      },
    },
  },
});
