import { Box, Tab, TabList, Tabs } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import { pricePrettier } from "../../core/utils";
import { useEffect, useState } from "react";
import { getPriceRangeDbDataFetcher } from "../../core/RpcDataFetcher";
import { loadingChart } from "../../types";

const shimmercap_green_color = "#1bb18c";

export default function PriceAssetChart(assetAddress: string) {
  let dateFormat = "EEE d";

  const [selectedTab, setSelectedTab] = useState(0);
  const [dateFormatState, setDateFormatState] = useState("EEE d");
  const [priceRangeDataState, setPriceRangeDataState] = useState(loadingChart);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const changeGraph = async () => {
      try {
        // Set 1 day by default
        setDateFormatState("HH:mm");
        setPriceRangeDataState(
          await getPriceRangeDbDataFetcher(assetAddress, 1, 3)
        );
        setIsLoading(false);

        switch (selectedTab) {
          case 0: // 1day
            setIsLoading(true);
            setDateFormatState("HH:mm");
            setPriceRangeDataState(
              await getPriceRangeDbDataFetcher(assetAddress, 1, 3)
            );
            setIsLoading(false);
            break;
          case 1: // 7days
            setIsLoading(true);
            setDateFormatState("EEE d");
            setPriceRangeDataState(
              await getPriceRangeDbDataFetcher(assetAddress, 7, 30)
            );
            setIsLoading(false);
            break;
          default:
            break;
        }
      } catch (error) {}
    };
    changeGraph();
  }, [selectedTab]);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box width={"full"} height={"500px"} marginStart={3} marginBottom={10}>
      <Box textColor={"white"} textAlign={"right"}>
        <Tabs
          variant="enclosed"
          align="end"
          marginEnd={12}
          onChange={(index) => handleTabChange(index)}
          colorScheme={"green"}
        >
          <TabList>
            <Tab>1D</Tab>
            <Tab>7D</Tab>
            <Tab isDisabled={true}>1M</Tab>
            <Tab isDisabled={true}>3M</Tab>
            <Tab isDisabled={true}>1Y</Tab>
            <Tab isDisabled={true}>All</Tab>
          </TabList>
        </Tabs>
      </Box>

      <ResponsiveContainer>
        <LineChart data={priceRangeDataState} syncId="ChartPrice">
          <CartesianGrid strokeDasharray="5 10000" />
          <XAxis
            dataKey="timestamp"
            tick={{ fill: "#CCCCCC" }}
            tickFormatter={(timestamp) =>
              `   ${format(timestamp * 1000, dateFormatState)}   `
            }
          />
          <YAxis
            orientation="right"
            domain={["auto", "auto"]}
            tick={{ fill: "#CCCCCC" }}
            tickFormatter={(value) => `$${pricePrettier(value)}`}
          />
          <Tooltip
            formatter={(value) => [`$${pricePrettier(Number(value))}`]}
            labelFormatter={(value) =>
              `Date: ${format(value * 1000, "HH:mm -- d MMM y z")}`
            }
          />

          <Line
            type="monotone"
            dot={false}
            strokeWidth={1.7}
            dataKey="price_usd"
            stroke={shimmercap_green_color}
            animationEasing="ease-in-out"
            animationDuration={60}
          />
          <Brush
            height={22}
            fill="#BFBFBF"
            startIndex={0}
            tickFormatter={(_, index) =>
              `   ${format(
                priceRangeDataState[index].timestamp * 1000,
                dateFormat
              )}   `
            }
          />

          {isLoading && (
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill="white"
              fontSize="30"
            >
              Loading Chart data...
            </text>
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
