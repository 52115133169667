import {
  Box,
  Heading,
  Container,
  Text,
  GridItem,
  Grid,
  Image,
} from "@chakra-ui/react";

let col_span = 10;
let color_text_base = "#    "; // brown dark

let bg_color_3 = "#D6CAAB"; // LIGHT BROWN

// Rainbow colors
let bg_rb_green = "#97BE9D";
let bg_rb_light_green = "green.200";
let bg_rb_blue = "#A6C0E8";
let bg_rb_purple = "#C6A9D0";
let bg_rb_orange = "#FFCE9E";

export default function Roadmap() {
  return (
    <Box
      backgroundColor={"gray.800"}
      backgroundImage={"bg_white.png"}
      backgroundSize={"cover"}
    >
      <Container maxW="container.lg" py={"60px"}>
        <Heading
          color={"gray.100"}
          fontWeight={"bold"}
          fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
        >
          Roadmap
        </Heading>

        <Grid
          marginY={"30px"}
          templateRows="(4, 1fr)"
          templateColumns="repeat(21, 1fr)"
          gap={4}
        >
          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="right">
              <p>
                <b>Launch Beta Version</b>
              </p>
              Beta version will be a visually improved version of the Alpha
              version
            </Text>
          </GridItem>

          <GridItem
            rowSpan={6}
            colSpan={1}
            borderRadius={"50px"}
            bg={bg_rb_light_green}
            transition="transform 250ms"
            _hover={{
              transform: "scaleX(1.1) scaleY(1.01)",
            }}
          />

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 10/30/23
              </Text>
            </Box>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 11/05/23
              </Text>
            </Box>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="left">
              <p>
                <b>Cover all Important Shimmer Assets and DEXs.</b>
              </p>
              By completing this point we should have covered most of the
              important tokens within ShimmerCap with their correct values.
            </Text>
          </GridItem>
          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="right">
              <p>
                <b>
                  Add % price change by range of time in Hero for each Asset.
                </b>
              </p>
              This is a Big Milestone btw, this means that we will already have
              our own price Database (Independent of any DeFi Platform).{" "}
            </Text>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 11/19/23
              </Text>
            </Box>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 01/21/24
              </Text>
            </Box>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="left">
              <p>
                <b>Each Asset will have its own Page implemented.</b>
              </p>
              When the User clicks on a certain Asset, a page will open with its
              specific information, such more detailed info about the Asset and
              the list of Pools that make up its price.{" "}
            </Text>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="right">
              <p>
                <b>
                  Develop and Implement Temporal Price Charts in Asset page.
                </b>
              </p>
              ShimmerCap will have the ability to show the price history of each
              Asset, this will increase the potential of the project.
            </Text>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 02/04/24
              </Text>
            </Box>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="120px"
              m={8}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            >
              {" "}
              <Image src={"check.svg"} boxSize="2.5rem" mr="12px" />
              <Text color={"gray.100"} fontSize={"2xl"}>
                Done 02/12/24
              </Text>
            </Box>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="left">
              <p>
                <b>Launch Stable Release Version.</b>
              </p>{" "}
            </Text>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="right">
              <p>
                <b>Add Trust Score (TS) to each asset.</b>
              </p>
              An Exhaustive Security Analysis of the project of each Asset is
              carried out on our part. The goal is to provide the user with
              tools so they know what they are buying."
            </Text>
          </GridItem>

          <GridItem
            rowSpan={3}
            colSpan={1}
            borderRadius={"50px"}
            bg={bg_rb_purple}
            transition="transform 250ms"
            _hover={{
              transform: "scaleX(1.1) scaleY(1.01)",
            }}
          />

          <GridItem colSpan={col_span}>
            <Box
              w="200px"
              m={12}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            ></Box>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="90px"
              m={4}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            ></Box>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="left">
              <p>
                <b>
                  Get other important Stats such as Volume data, Price
                  Performance, among others.
                </b>
              </p>
            </Text>
          </GridItem>

          <GridItem
            colSpan={col_span}
            bg={bg_color_3}
            border={"2px solid"}
            borderColor={color_text_base}
            borderRadius={"30px"}
            color={color_text_base}
            fontSize={17}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.04)",
              bg: bg_rb_blue,
            }}
          >
            <Text m={6} textAlign="right">
              <p>
                <b>Investigate new improvements for the future.</b>
              </p>
            </Text>
          </GridItem>

          <GridItem colSpan={col_span}>
            <Box
              w="200px"
              m={12}
              transition="transform 250ms"
              _hover={{
                transform: "scale(1.08)",
              }}
            ></Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
}
