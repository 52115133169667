import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Divider,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export default function WhatIs() {
  return (
    <Box bg="gray.800">
      <Container maxW="container.xl">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            color={"gray.100"}
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            What is
            <br />
            <Text as={"span"} color={"#1bb18c"}>
              ShimmerCap?
            </Text>
          </Heading>
          <Text
            fontSize="2xl"
            color={"gray.100"}
            align={"left"}
            maxW="800px"
            alignSelf={"center"}
          >
            -{" "}
            <b>
              It's a Monitor of all the Tokens/Assets that we can find in the{" "}
              <i>Shimmer Network</i>.
            </b>{" "}
            In ShimmerCap you will find out about the main information of each
            Token launched on the Network.
          </Text>
          <Divider />

          <Text
            fontSize="4xl"
            fontWeight={600}
            color={"gray.100"}
            align={"left"}
            maxW="900px"
            alignSelf={"center"}
          >
            What is the Objective of ShimmerCap?
          </Text>

          <Text
            fontSize="2xl"
            color={"gray.100"}
            align={"left"}
            maxW="800px"
            alignSelf={"center"}
          >
            -{" "}
            <i>
              <b>ShimmerCap's</b>
            </i>{" "}
            purpose is to always be a Fast and Precise tool within the User's
            reach, without any unnecessary detours.
          </Text>
          <Divider />
          <Text
            fontSize="4xl"
            fontWeight={600}
            color={"gray.100"}
            align={"left"}
            maxW="900px"
            alignSelf={"center"}
          >
            What is the profit that ShimmerCap has?
          </Text>
          <Text
            fontSize="2xl"
            color={"gray.100"}
            align={"left"}
            maxW="800px"
            alignSelf={"center"}
          >
            -{" "}
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            is a <b>NON-PROFIT PROJECT</b>. It does not receive external funding
            nor is it seeking it and is only financed by{" "}
            <ChakraLink
              as={ReactRouterLink}
              to="/donations"
              textColor={"blue.300"}
            >
              user donations
            </ChakraLink>
            . The only objective of this project is for users of the{" "}
            <i>Shimmer Network</i> to receive accurate information so that they
            can be owners of their money, without taking partisanship for any
            token or protocol.
          </Text>
          <Divider />

          <Text
            fontSize="4xl"
            fontWeight={600}
            color={"gray.100"}
            align={"left"}
            maxW="900px"
            alignSelf={"center"}
          >
            Where does ShimmerCap get the data from?
          </Text>

          <Text
            fontSize="2xl"
            color={"gray.100"}
            align={"left"}
            maxW="800px"
            alignSelf={"center"}
          >
            - Most of the{" "}
            <i>
              <b>ShimmerCap</b>
            </i>{" "}
            Information is obtained through the EVM Blockchain, the Prices are
            obtained from the different DEXs where the token is found with
            unrestricted buy and sale, and the general information of the Token
            itself is obtained both in the Blockchain, as in information that is
            in the public domain.
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
