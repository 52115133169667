import { WhitelistTokenData } from "./types";

export const shimmercapApiUrl = `https://shimmercap-api.vercel.app/api/`;

export const whitelistTokenData: WhitelistTokenData[] = [
  {
    Address: "0x1074010000000000000000000000000000000000",
    ImageURL: "/assetLogos/smr.svg",
    Name: "Shimmer",
    Symbol: "SMR",
    Details: {
      Description:
        "<b>SMR</b> or <b>Shimmer</b> is the <i>Native Asset</i> de <i>Shimmer Network (Layer 1)</i> and <i>ShimmerEVM Network (Layer2)</i>.<br><br><i>Shimmer Network</i>  is the <i>Canary Network</i> of <i>IOTA</i>, it’s being developed by the <i>IOTA Foundation</i> (IF).<br><br><b>SMR Token</b> has been listed on Major Exchanges like <i>BitFinex</i> and <i>BitForex</i>.",
      OfficialLinks: {
        website: "https://shimmer.network/",
        tokenPage: "https://shimmer.network/token",
        github: "https://github.com/iotaledger",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/shimmernet/",
        discord: "https://discord.shimmer.network/",
        reddit: "https://www.reddit.com/r/shimmer/",
      },
      Markets: [
        {
          exchange: "Bitfinex",
          assets: ["USDT"],
          type: "Trading Pair",
          address: "",
        },
        {
          exchange: "ShimmerSea",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0xa3e6fbf50a05e6033ed48adc2351c6099b0b2499",
        },
        {
          exchange: "ShimmerSea",
          assets: ["LUM"],
          type: "DEX Pool",
          address: "0x95f00a7125ec3d78d6b2fcd6ffd9989941ef25fc",
        },
        {
          exchange: "ShimmerSea",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0x59284c3ef3c4fea5fecbda5697ed9c3f4bfa37f3",
        },
        {
          exchange: "TangleSwap",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0xa0e105b9300cfa9564126a705d6e5bc9e05de618",
        },
        {
          exchange: "TangleSwap",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0xa0e105b9300cfa9564126a705d6e5bc9e05de618",
        },
        {
          exchange: "TangleSwap",
          assets: ["FUEL"],
          type: "DEX Pool",
          address: "0x119af9ae42be48c1dbdb30169f4ae08841b28d6f",
        },
        {
          exchange: "IotaBee",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "IotaBee",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "",
        },
      ],
    },
  },
  {
    Address: "0x4794Aeafa5Efe2fC1F6f5eb745798aaF39A81D3e",
    ImageURL: "/assetLogos/lum.svg",
    Name: "Lum Token",
    Symbol: "LUM",
    Details: {
      Description: `<b>LUM</b> is the Utility Token of the <i>ShimmerSea</i> Platform. <br><br><i>ShimmerSea</i> is a platform that focuses primarily on its DEX product (<i>UniswapV2</i> fork), being (as of this writing) the DEX with the most Liquidity on the <i>ShimmerEVM network</i> (according to the amount of token liquidity in their main contracts). <br><i>ShimmerSea</i> also offers other products such as the ability to Stake and Farm certain selected tokens, an NFT Marketplace, a token Fairlaunch platform, among other products. <br><br>The LUM token is delivered as a reward in <i>ShimmerSea</i> platform products such as entering liquidity into the Pools, and in Earn products such as Farm and Stake, so the main use of the LUM token is to be an additional economic incentive for users, attracting this way more liquidity to the platform.`,
      OfficialLinks: {
        website: "https://shimmersea.finance/",
        tokenPage: "https://tokenomics.shimmersea.finance/docs/Chapter2",
        github: "https://github.com/ShimmerSea",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/ShimmerSeaDEX",
        discord: "https://discord.gg/zKgPXY6K97",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x95f00a7125ec3d78d6b2fcd6ffd9989941ef25fc",
        },
        {
          exchange: "ShimmerSea",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0x756bc5a3ed1fe7b7bd20848d4038fe93e377987d",
        },
      ],
    },
  },
  {
    Address: "0xf21Bc150F4D08Dce61bd16cDbfcB3D212b575b26",
    ImageURL: "/assetLogos/mlum.svg",
    Name: "MagicLum Token",
    Symbol: "MLUM",
    Details: {
      Description: `<b>MLUM</b> is the Governance Token of the <i>ShimmerSea</i> Platform. <br><br><i>ShimmerSea</i> is a platform that focuses primarily on its DEX product (<i>UniswapV2</i> fork), being (as of this writing) the DEX with the most Liquidity on the <i>ShimmerEVM network</i> (according to the amount of token liquidity in their main contracts). <br><i>ShimmerSea</i> also offers other products such as the ability to Stake and Farm certain selected tokens, an NFT Marketplace, a token Fairlaunch platform, among other products. <br><br>The <b>MLUM Token</b> offers several features for the User, among the most notable is that holding <b>MLUM</b> gives the user <i>Voting</i> possibilities in the <i>ShimmerSea DAO</i>, and also, <b>MagicLum</b> holders receive rewards via selected <i>staking pools</i>.<br><br><i>ShimmerSea</i> offers a conversion mechanism from the reward token <b>LUM</b> to <b>MLUM</b>, this conversion is not direct but rather obeys a variable rate with a timelock period defined in a contract called <i>Booster</i>.<br><br><b>MLUM</b> Max Total Supply is defined by contract at 1M Tokens.`,
      OfficialLinks: {
        website: "https://shimmersea.finance/",
        tokenPage: "https://tokenomics.shimmersea.finance/docs/Chapter2",
        github: "https://github.com/ShimmerSea",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/ShimmerSeaDEX",
        discord: "https://discord.gg/zKgPXY6K97",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0x9b6b4860657b08a611291abe3133645e3a69ce72",
        },
      ],
    },
  },
  {
    Address: "0xE5f3dCC241Dd008E3c308e57cf4F7880eA9210F8",
    ImageURL: "/assetLogos/void.png",
    Name: "Void Token",
    Symbol: "VOID",
    Details: {
      Description:
        "<b>VOID</b> is the Utility Token of the <i>TangleSwap</i> Platform.<br><br><i>TangleSwap</i> is a platform that focuses primarily on its DEX product (<i>UniswapV3</i> fork).<br><br><i>TangleSwap</i> also offers other products such as the ability to <i>Stake</i> and <i>Farm</i> certain selected tokens, a token <i>Crowdsale Campaing</i> platform, among other products.<br><br>The <b>VOID</b> token is delivered as a reward in <i>TangleSwap</i> platform products such as entering liquidity into the pools, and in Earn products such as <i>Stake</i> and <i>Farm</i> , so the main use of the <b>VOID</b> token is to be an additional economic incentive for users, attracting this way more liquidity to the platform.<br><br><b>VOID Token </b>(and <i>TangleSwap</i>) has been developed by <b>Sevane</b> (@PilotSevane on XTwitter), <b>Tstrong</b> (@PilotTstrong on Twitter) among other developers.",
      OfficialLinks: {
        website: "https://www.tangleswap.exchange",
        tokenPage: "https://docs.tangleswap.exchange/token/the-void",
        github: "https://github.com/TangleSwap",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/TangleSwap",
        discord: "https://discord.com/invite/5DMsPShwDz",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0x4cd37f97e89b6a87c1b717321b9a04f8c8a9ac73",
        },
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xe04b99e0c707a964258f9e2a9d7b54baf4fd070f",
        },
      ],
    },
  },
  {
    Address: "0xece555d37c37d55a6341b80cf35ef3bc57401d1a",
    ImageURL: "/assetLogos/usdc_lz.png",
    Name: "Bridged LayerZero USDC",
    Symbol: "USDC",
    Details: {
      Description:
        "<b>USDC</b> is a <i>Bridged Token</i> of the Asset <b>USDC</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>LayerZero</i>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text). The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.<br><br><b>USDC</b> is a stablecoin that is pegged to the value of <b>USD Dollar</b>. The PEG of the Token in USDC is centrally controlled by the <i>Centre Consortium</i> and obtained by maintaining reserves in the physical world in a sum that equals the number of USDC circulating in the network.",
      OfficialLinks: {
        website: "https://layerzero.network/",
        tokenPage: "https://docs.layerzero.network/contracts/oft",
        github: "https://github.com/LayerZero-Labs/LayerZero-v2",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/LayerZero_Labs",
        discord: "https://discord.com/invite/6B2kt4v",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0xb587996d7949781a9fc7db29bf309b74762ce627",
        },
        {
          exchange: "ShimmerSea",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "0x4a72239420c3ce628ae4776dca690117cb58265b",
        },
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xc2065246f1903cf9f675b9cd8546f843e0170bd9",
        },
        {
          exchange: "IotaBee",
          assets: ["USDT"],
          type: "DEX Pool",
          address: "",
        },
      ],
    },
  },
  {
    Address: "0xa4f8C7C1018b9dD3be5835bF00f335D9910aF6Bd",
    ImageURL: "/assetLogos/usdt_lz.png",
    Name: "Bridged LayerZero USDT",
    Symbol: "USDT",
    Details: {
      Description:
        "<b>USDT</b> is a <i>Bridged Token</i> of the Asset <b>USDT</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>LayerZero</i>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text). The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.<br><br><b>USDT</b> is a stablecoin that is pegged to the value of <b>USD Dollar</b>. The PEG of the Token in USDT is centrally controlled by a series of companies and obtained by maintaining reserves in the physical world in a sum that equals the number of USDT circulating in the network.",
      OfficialLinks: {
        website: "https://layerzero.network/",
        tokenPage: "https://docs.layerzero.network/contracts/oft",
        github: "https://github.com/LayerZero-Labs/LayerZero-v2",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/LayerZero_Labs",
        discord: "https://discord.com/invite/6B2kt4v",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["USDC"],
          type: "DEX Pool",
          address: "0xb587996d7949781a9fc7db29bf309b74762ce627",
        },
        {
          exchange: "ShimmerSea",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0xef668c0ed3b535f0f8136ae1a8ce54993376b5e9",
        },
        {
          exchange: "TangleSwap",
          assets: ["USDC"],
          type: "DEX Pool",
          address: "0xb587996d7949781a9fc7db29bf309b74762ce627",
        },
        {
          exchange: "ShimmerSea",
          assets: ["USDC"],
          type: "DEX Pool",
          address: "0x4a72239420c3ce628ae4776dca690117cb58265b",
        },
        {
          exchange: "ShimmerSea",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0xf8a56fc8bf20b82bb5cb62bcd0b0daaf9577b467",
        },
        {
          exchange: "IotaBee",
          assets: ["USDC"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "ShimmerSea",
          assets: ["LUM"],
          type: "DEX Pool",
          address: "0x756bc5a3ed1fe7b7bd20848d4038fe93e377987d",
        },
      ],
    },
  },
  {
    Address: "0x4638C9fb4eFFe36C49d8931BB713126063BF38f9",
    ImageURL: "/assetLogos/eth_lz.png",
    Name: "Bridged LayerZero ETH",
    Symbol: "ETH",
    Details: {
      Description:
        "<b>WETH</b> is a <i>Bridged Token</i> of the Asset <b>ETH</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>LayerZero</i>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text). The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.<br><br><b>ETH</b> or <b>Ethereum</b> is the native asset of the <i>Ethereum Network</i>",
      OfficialLinks: {
        website: "https://layerzero.network/",
        tokenPage: "https://docs.layerzero.network/contracts/oft",
        github: "https://github.com/LayerZero-Labs/LayerZero-v2",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/LayerZero_Labs",
        discord: "https://discord.com/invite/6B2kt4v",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x152d0436369aab0a83ca5f45b7c0da3f26fc0fa9",
        },
        {
          exchange: "TangleSwap",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "0x32543798b108bfcf95bc60af6111f01155b262d6",
        },
      ],
    },
  },
  {
    Address: "0xb0119035d08CB5f467F9ed8Eae4E5f9626Aa7402",
    ImageURL: "/assetLogos/wbtc_lz.png",
    Name: "Bridged LayerZero wBTC",
    Symbol: "WBTC",
    Details: {
      Description:
        "<b>WBTC</b> is a <i>Bridged Token</i> of the Asset <b>WBTC</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>LayerZero</i>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text). The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.<br><br><b>WBTC</b> or <b>Wrapped BTC</b> is a stablecoin that is pegged to the value of Bitcoin (BTC). The PEG of the Token in BTC is centrally controlled by the <i>BitGo</i> company and obtained by maintaining BTC reserves in a wallet on the <i>Bitcoin Network</I> in a sum that equals the number of WBTC circulating in the network.",
      OfficialLinks: {
        website: "https://layerzero.network/",
        tokenPage: "https://docs.layerzero.network/contracts/oft",
        github: "https://github.com/LayerZero-Labs/LayerZero-v2",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/LayerZero_Labs",
        discord: "https://discord.com/invite/6B2kt4v",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x8bd31a18ef549c5c3b7673a251e188e7091df055",
        },
      ],
    },
  },

  {
    Address: "0x326f23422CE22Ee5fBb5F37f9fa1092d095546F8",
    ImageURL: "/assetLogos/deepr.svg",
    Name: "Deepr Token",
    Symbol: "DEEPR",
    Details: {
      Description: `<b>DEEPR</b> is the Utility Token of the <i>Deepr</i> Platform. <br><br><i>Deepr</i> is a <i>DeFi Borrow/Lending</i> platform, being a <i>Compound</i> fork. As of writing this, <i>Deepr</i> is the only Borrow/Lending platform with verifiable liquidity on the <i>ShimmerEVM network</i>. <br><br><b>DEEPR Token</b> is delivered as a reward for users who make Supply Collateral and/or Borrow in certain Markets of the <i>Deepr</i> platform, so the main use of the <b>DEEPR</b> Token is to be an additional economic incentive for users, attracting this way more liquidity to the platform.<br><br><b>Deepr Token</b> (and <i>Deepr Platform</i>) has been developed by the company <i>Nakama Labs</i> (@Nakama_Labs on XTwitter)`,
      OfficialLinks: {
        website: "https://www.deepr.finance/",
        tokenPage: "https://docs.deepr.finance/tokenomics/",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/DeeprFinance",
        discord: "https://discord.gg/kfyNmmbvhJ",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xd56c46dde3079bb7799826c6bff217665206100b",
        },
      ],
    },
  },
  {
    Address: "0x3c844fb5ad27a078d945ddda8076a4084a76e513",
    ImageURL: "/assetLogos/ssoon.png",
    Name: "SOON Token",
    Symbol: "sSOON",
    Details: {
      Description:
        "<b>sSOON</b> is a <i>Bridged Wrapped Token</i> of the original <b>SOON</b> Token launched on the <i>IOTA Network (L1)</i>.<br><br>The <b>sSOON</b> Token is the utility Token of the <i>Soonaverse</i> project, <i>Soonaverse</i> has developed a series of platforms deployed in <i>IOTA Network L1</i>, <i>Shimmer Network L1</i> and<i>L2 (EVM)</i>.<br><br>For the <i>ShimmerEVM</i> user, the greatest potential of the <b>sSOON</b> Token seems to be (at the time of writing) in the <i>IotaBee DEX</i> where the user has the possibility of earning <b>sSOON</b> as a reward by providing liquidity in the pools and participating in Stake Farms provided by the platform.<br><br><b>sSOON Token</b> (and <i>Soonaverse</i>) has been developed by the company <i>SoonLabs</i> (@soon_labs on XTwitter).",
      OfficialLinks: {
        website: "https://soonaverse.com/home",
        tokenPage: "https://docs.soonaverse.com/en/token/token-distribution",
        github: "https://github.com/soonaverse",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/soon_labs",
        discord: "https://discord.com/invite/UffhtduynB",
      },
      Markets: [
        {
          exchange: "IotaBee",
          assets: ["sETH"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "IotaBee",
          assets: ["sBTC"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "IotaBee",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "IotaBee",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x9b9dbe4dceba4f47278722bce1438410a694513d",
        },
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x940d168b9b304495f94d0d879c8dfe4c420156e5",
        },
      ],
    },
  },
  {
    Address: "0x5dA63f4456A56a0c5Cb0B2104a3610D5CA3d48E8",
    ImageURL: "/assetLogos/siota.png",
    Name: "Bridged IOTAMPC IOTA",
    Symbol: "sIOTA",
    Details: {
      Description:
        "<b>sIOTA</b> is a <i>Bridged Token</i> of the Asset <b>IOTA</b> that we can find on the <i>IOTA Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>TanglePay</i> through the <i>IOTAMPC</i> protocol and contracts, this is a protocol that allows assets to be exchanged between the <i>ShimmerEVM Network</i> and the <i>Ethereum Network</i> and <i>IOTA L1 Network</i>. The <i>IOTAMPC</i> relies on a discrete number of validators that check that transactions are valid.<br><br><b>IOTA</b> is the native asset of the <i>IOTA Network</i>",
      OfficialLinks: {
        website: "https://iotampc.com/mpc.html",
        github: " https://github.com/TanglePay/bridge",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/iotampc",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["ETH"],
          type: "DEX Pool",
          address: "0x32543798b108bfcf95bc60af6111f01155b262d6",
        },
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x59284c3ef3c4fea5fecbda5697ed9c3f4bfa37f3",
        },
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x9f543d78f4e8dfebab744e0ead46b8b732d1b932",
        },
        {
          exchange: "IotaBee",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "IotaBee",
          assets: ["sSOON"],
          type: "DEX Pool",
          address: "",
        },
      ],
    },
  },
  {
    Address: "0xa158A39d00C79019A01A6E86c56E96C461334Eb0",
    ImageURL: "/assetLogos/seth.png",
    Name: "Bridged IOTAMPC ETH",
    Symbol: "sETH",
    Details: {
      Description:
        "<b>sETH</b> is a <i>Bridged Token</i> of the Asset <b>ETH</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>TanglePay</i> through the <i>IOTAMPC</i> protocol and contracts, this is a protocol that allows assets to be exchanged between the <i>ShimmerEVM Network</i> and the <i>Ethereum Network</i> and <i>IOTA L1 Network</i>. The <i>IOTAMPC</i> relies on a discrete number of validators that check that transactions are valid.<br><br><b>ETH</b> or <b>Ethereum</b> is the native asset of the <i>Ethereum Network</i>",
      OfficialLinks: {
        website: "https://iotampc.com/mpc.html",
        github: " https://github.com/TanglePay/bridge",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/iotampc",
      },
      Markets: [
        {
          exchange: "IotaBee",
          assets: ["sSOON"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x096a93e83596f953c17afbf65e925f477880ea0e",
        },
      ],
    },
  },
  {
    Address: "0x1cdf3f46dbf8cf099d218cf96a769cea82f75316",
    ImageURL: "/assetLogos/sbtc.png",
    Name: "Bridged IOTAMPC BTC",
    Symbol: "sBTC",
    Details: {
      Description:
        "<b>sBTC</b> is a <i>Bridged Token</i> of the Asset <b>WBTC</b> that we can find on the <i>Ethereum Mainnet Network</i>. The Bridge of this Asset is carried out by the company <i>TanglePay</i> through the <i>IOTAMPC</i> protocol and contracts, this is a protocol that allows assets to be exchanged between the <i>ShimmerEVM Network</i> and the <i>Ethereum Network</i> and <i>IOTA L1 Network</i>. The <i>IOTAMPC</i> relies on a discrete number of validators that check that transactions are valid.<br><br><b>WBTC</b> or <b>Wrapped BTC</b> is a stablecoin that is pegged to the value of Bitcoin (BTC). The PEG of the Token in BTC is centrally controlled by the <i>BitGo</i> company and obtained by maintaining BTC reserves in a wallet on the <i>Bitcoin Network</I> in a sum that equals the number of WBTC circulating in the network.",
      OfficialLinks: {
        website: "https://iotampc.com/mpc.html",
        github: " https://github.com/TanglePay/bridge",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/iotampc",
      },
      Markets: [
        {
          exchange: "IotaBee",
          assets: ["sSOON"],
          type: "DEX Pool",
          address: "",
        },
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xc4b294d27082dd89d9191b4957c4e69c5b48acd7",
        },
      ],
    },
  },
  {
    Address: "0x174d211f46994860500db4d66b3efe605a82bf95",
    ImageURL: "/assetLogos/aur.png",
    Name: "Aureus Token",
    Symbol: "AUR",
    Details: {
      Description:
        "<b>Aureus</b> or <b>AUR</b> is the main Token of the <i>IotaOrigin</i> project.<br><br><i>IotaOrigin</i> is a project with application in the physical world, it aims to help manage greater efficiency and innovation protocols in mineral extraction (mining) processes. Quoting an extract obtained from its main website: “IotaOrigin is working to standardize and digitize trading, financing, insurance, and international contracts in the mining sector. DLT and IOTA in particular offer us the best tools to accomplish our tasks.”<br><br><b>AUR</b> (and the <i>IotaOrigin</i> project) has been developed by the Germany-based company <i>IotaOrigin UG</i>.",
      OfficialLinks: {
        website: "https://iotaorigin.de",
        tokenPage:
          "https://medium.com/@iotaorigin/iotaorigin-whitepaper-nft-pools-3fa68f8cb7ac",
        github: "https://github.com/IotaOrigin",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/origin_iota",
        discord: "https://discord.com/invite/MkTjZKE8fC",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x2215783d1d905954ea20f34267dcf1a3f4e990e4",
        },
        {
          exchange: "IotaBee",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "",
        },
      ],
    },
  },
  {
    Address: "0x264F2e6142CE8bEA68e5C646f8C07db98A9E003A",
    ImageURL: "/assetLogos/ape.svg",
    Name: "APE DAO",
    Symbol: "APEin",
    Details: {
      Description:
        "<b>APEin</b> is the main utility token of the <i>ApeDAO</i> project. <i>ApeDAO</i> is focused on empowering its own community of users with membership access (with voting power), which is obtained by being the owner of one of its two randomly-generated NFT collections.<br><br><b>APEin</b> is the main utility token of the <i>ApeDAO</i> project. <i>ApeDAO</i> is a project aimed at promoting its own community of users with membership, you access to be a member of <i>ApeDAO</i> (with voting power) basically by being the owner of one of its two randomly-generated NFT collections. There is two NFT collection of Apes: <b>OG Apes</b> and <b>Lil’ Apes</b>. There is a Leaderboard where each user is ranked according to the amount of OG Apes, Lil' Apes or APEin Token they have.<br><br><b>APEin Token</b> holders have more benefits in <i>ApeDAO</i>.<br><br>Unfortunately (so far) <i>ShimmerCap</i> has not found any Web Page where it expands on the <i>Tokenomics</i> of the <b>APEin Token</b>. <i>ShimmerCap</i> undertakes to contact the developer for more information.<br><br><b>APEin Token</b> (and <i>ApeDAO</i>) has been developed by <b>Alpha Rho</b> (@0xAlphaRho in XTwitter)<br><br>",
      OfficialLinks: {
        website: "https://apedao.finance",
        tokenPage: "https://github.com/iotapes",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/0xApeDAO",
        discord: "https://discord.com/invite/WDxt6xZDDh",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xf187872d281c001552687479c8b92a0d45f0463f",
        },
      ],
    },
  },
  {
    Address: "0x83b090759017EFC9cB4d9E45B813f5D5CbBFeb95",
    ImageURL: "/assetLogos/fuel.png",
    Name: "Epoch Zero",
    Symbol: "FUEL",
    Details: {
      Description:
        "<b>FUEL</b> is the main Token of the <i>EpochZero</i> project. EpochZero, initially born in IOTA/Shimmer L1 Mainnet, aims to form its own community based on promoting various own projects, and promoting other projects from the ecosystem and users.<br><br><i>EpochZero</i> has a list of projects on its website where some have already been released. Among them we can highlight <i>Pharaoh Club </i> (DAO), an NFT Collection launched on Layer1, EpochZero's most important product.<br><br>According to a <i>ShimmerCap</i> research (the details can be explicitly found in its documentation), at the moment <i>EpochZero</i> does not offer a main objective or an obvious utility for the user holder of <b>FUEL</b> token.<br><br><b>FUEL Token</b> (and <i>EpochZero</i>) has been developed by <b>STiJO</b> (@STiJO_EZ on XTwitter) and <b>0DN_HyperMindz</b> (@Hyper_Mindz on XTwitter).",
      OfficialLinks: {
        website: "https://epochzero.io",
        tokenPage:
          "https://docs.epochzero.io/welcome/technicals/tokenomics/usdfuel-tokens",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/Epoch_0",
        discord: "https://discord.com/invite/7QHHFVfZ2X",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x119af9ae42be48c1dbdb30169f4ae08841b28d6f",
        },
      ],
    },
  },
  {
    Address: "0xbD17705cA627EFBB55dE22A0F966Af79E9191c89",
    ImageURL: "/assetLogos/rust.png",
    Name: "Rusty Robot Country Club",
    Symbol: "RUST",
    Details: {
      Description:
        "<b>RUST</b> is the main utility token of the <i>Rusty Robot Country Club</i> project. <i>Rusty Robot Country Club</i> is an NFT collection which aims to create a (citing the author) `collaborative, creative community` among its owners. Users become `members` by having the owner of a <i>Rusty Robot Country Club NFT</i>.<br><br><b>RUST Token</b> holders have more benefits in <i>Rusty Robot Country Club</i>.<br><br>It is not clear from the information obtained by official media who the developers of <i>Rusty Robot Country Club</i> are, however, according to the <i>Tangleverse</i> website (www.tangleverse.io) the developers of <i>Rusty Robot Country Club</i> are: <b>Pathin</b> (@ThePathin on XTwitter), <b>Andy</b>, <b>QQLA-17</b>, <b>Addommor</b>, among others. We cannot fully echo this information since, again, it is not obtained from official media but from an indirect source of information.",
      OfficialLinks: {
        website: "https://www.rustyrobot.io",
        tokenPage: "https://docs.rustyrobot.io/rust-token/rust-token-info",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/rustyrobotcc",
        discord: "https://discord.com/invite/8unzwNPYYC",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x9f43b71c94837f37700a5861d34c3bf8865cc728",
        },
      ],
    },
  },
  {
    Address: "0x4259b21AD6D95d747dDA908e9617443a530CD20d",
    ImageURL: "/assetLogos/wen.svg",
    Name: "WEN Token",
    Symbol: "WEN",
    Details: {
      Description:
        "<b>WEN</b> is a <i>memecoin</i> Token, it’s emblem figure are cartoon bunnies.<br><br>WEN Token has also been officially linked to NFT collections of its own, most notably `<i>WEN Bunnies</i>`.<br><br>The Creators/Developers of WEN Token are not public knowledge (as of this writing).",
      OfficialLinks: {
        website: "https://wen-shimmer.com",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/WEN_Shimmer",
        discord: "https://discord.com/invite/2QrFztaqkw",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xc4b294d27082dd89d9191b4957c4e69c5b48acd7",
        },
      ],
    },
  },
  {
    Address: "0xe051d1af4b1149FfB314C41C87E3501CFb961a73",
    ImageURL: "/assetLogos/fish.svg",
    Name: "Fish Token",
    Symbol: "FISH",
    Details: {
      Description:
        "<b>FISH</b> or <b>Big Fish</b> is apparently a <i>memecoin</i>, however <i>ShimmerCap</i> has so far found no official links that refer to this token or official information about its developers.<br><br>Be, the user, especially careful when using or investing in this token.",
      OfficialLinks: {},
      SocialLinks: {},
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x7930a8aa929bcb7c0d1a7f75a6f2a95159ac51c3",
        },
      ],
    },
  },
  {
    Address: "0x2F69dC2d27051493C775e4d36Be2bEBA5d7093E8",
    ImageURL: "/assetLogos/assmb.svg",
    Name: "Assembly Memecoin",
    Symbol: "ASSMB",
    Details: {
      Description:
        "<b>ASSMB</b> is a <i>memecoin Token</i>, it’s a clear parody of the already deprecated <i>IOTA Assembly</i> project (<i>ASMB</i>). Claims to be the first memecoin token on <i>ShimmerEVM</i>.<br><br>As of this writing, the token does not have a Webpage.<br><br>The Creators/Developers of WEN Token are not public knowledge (as of this writing).",
      OfficialLinks: {},
      SocialLinks: {
        xTwitter: "https://twitter.com/ASSMBtoken",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x456dc9b693225f7823b5f1929ee6d88bd89ad049",
        },
      ],
    },
  },
  {
    Address: "0x22d9b9F1b53A872D04A3DBFe3f61B7d21aA54D43",
    ImageURL: "/assetLogos/love.png",
    Name: "Love Token",
    Symbol: "LOVE",
    Details: {
      Description:
        "<b>Love</b> is the main Token of <i>Chakralabs</i>.<br><br>At the time of writing this text, <i>Chakralabs</i> has ceased to exist, its Website and Twitter no longer exist.<br><br>There are great indications and evidence that this Token has suffered a <b>Rug Pull</b>.<br>Be, the user, especially careful when using or invest in this token.",
      OfficialLinks: {},
      SocialLinks: {},
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x7cc767ef061c1a7eca3f3a82d2447f2ceb9987a9",
        },
      ],
    },
  },
  {
    Address: "0xE9308Bf2d95d11E324E0C62FF24bBD4bbc5dA546",
    ImageURL: "/assetLogos/sddt.png",
    Name: "Shimmer Doge DAO Token",
    Symbol: "SDDT",
    Details: {
      Description:
        "<b>SDDT</b> is the main token of <i>Shimmer Doge DAO</i>. <i>Shimmer Doge DAO</i> is made up of a series of platforms and projects oriented to the Metaverse, users can become members and have participation in the DAO by acquiring <b>SDDT Token</b> or the <i>Shimmer Doge DAO Pass NFT</i>.<br><br>On its official website we can find the following quote: “The main goal of the (Shimmer Doge DAO) community is to support the development of the IOTA ecosystem and reward active DAO members.",
      OfficialLinks: {
        website: "https://shimmerzone.net",
        tokenPage:
          "https://shimmer-doge-dao.gitbook.io/shimmer-doge-dao/dao-overview/sddt-tokenomiks",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/shimmerdogedao",
        discord: "https://discord.com/invite/E3pt9awPaZ",
      },
      Markets: [
        {
          exchange: "TangleSwap",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xaff1a99775861fc3c367411f10eff3bb45c49d9a",
        },
        {
          exchange: "IotaBee",
          assets: ["sIOTA"],
          type: "DEX Pool",
          address: "",
        },
      ],
    },
  },
  {
    Address: "0x3bBb9B7848De06778fEE4fE0bC4d9AB271e56648",
    ImageURL: "/assetLogos/ihg.svg",
    Name: "IHG Token",
    Symbol: "IHG",
    Details: {
      Description:
        "<b>IHG</b> or <b>GOLD</b> is a Utility Token of the <i>IOTA Heroes</i> Game. <i>IOTA Heroes</i> is a <i>Blockchain Game</i> that is about a character that the user can control called <i>Hero</i> (an NFT), the <i>Hero</i> can equip parts (armor and weapons), be trained in <i>Training Facilities</i>, Craft, among other activities. In the future, it is planned to enable <i>PvP</i> duels and other <i>Play to Earn</i> activities for the <i>Heroes</i>.<br><br><b>IHG</b> or <b>GOLD</b> is one of the supported currencies (the other is <i>SMR</i>) of the game, to buy, acquire or rent <i>game items</i>. For example, to use a Training Facilitie, it can only be done with <i>IHG</i>.<br><br>Unfortunately (so far) <i>ShimmerCap</i> has not found any Web Page that expands on the <i>Tokenomics</i> of the <b>IHG</b> or <b>GOLD</b> Token. <i>ShimmerCap</i> undertakes to contact the developer for more information.<br><br>IHG Token (and IOTA Heroes) has been developed by <b>Christian Henriksen</b> (@MrCrelde on XTwitter) and graphic artist <b>FRESHCAKES</b> (@rafilopez on Behance).",
      OfficialLinks: {
        website: "https://www.iotaheroes.com",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/IotaHeroes",
        discord: "https://discord.com/invite/WC2S9AjuXY",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x9ca85c7435a0a4989bdf7904b096ea66c902b401",
        },
      ],
    },
  },
  {
    Address: "0x8E9b86C02F54d4D909e25134ce45bdf2B6597306",
    ImageURL: "/assetLogos/fuse.png",
    Name: "Fusing Failstack Token",
    Symbol: "FUSE",
    Details: {
      Description:
        "<b>FUSE</b> is the utility token of a future NFT-card game called <i>Infernal Uprising</i>. <i>Infernal Uprising</i> (which at the time of writing is in `Early Stage`) is a round-based strategy card-game. To get the cards the user must have an <i>Infernal Uprising NFT</i> since these NFTs will unlock game-cards in the game. Cards have up to 5 <i>rarity</i> levels, with level 5 being the rarest.<br><br><b>FUSE Token</b> will have an important role in the game mechanics since, as the main mechanic of the token, it will be necessary to fuse two cards of the same <i>rarity</i> to obtain one of higher <i>rarity</i> and <i>rank</i>.<br><br><b>FUSE Token</b> (and Infernal Uprising) has been and is being developed by <b>DigitalSoul.x</b> (@DigitalSoulx on XTwitter), <b>Linus Naumann</b> (@LinusNaumann on Twitter), <b>Sefear</b> (@Sefear_ on XTwitter), <b>Pathin</b> (@ThePathin on XTwitter) among others developers.",
      OfficialLinks: {
        website: "https://www.infernaluprising.com",
        tokenPage:
          "https://www.infernaluprising.com/blog/tokenomics-and-fairlaunch",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/InfernalNFTs",
        discord: "https://discord.com/invite/wbBH8mPhhB",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xf1f3ef76bec919d7190df450847f6bbfe322af61",
        },
      ],
    },
  },
  {
    Address: "0x32D5b7f003b679E387bD986a89232cccC6996b54",
    ImageURL: "/assetLogos/squid.png",
    Name: "Grumpy Squid Token",
    Symbol: "SQUID",
    Details: {
      Description:
        "<b>SQUID</b> is the main Token of the <i>Grumpy Squid Project</i>.<br><br>The <i>Grumpy Squid Project</i> is a project where the developers place particular emphasis on the economic system of its main token, <b>SQUID</b>.<br>The project introduces an innovative economic system where a 10% fee is charged for each Swap Transaction on a decentralized exchange (DEX). Out of this fee, 5% is burned, and the remaining 5% is redistributed to all <b>SQUID</b> holders relative to their holdings. This transforms this asset into a <b>deflationary Token</b> while simultaneously rewarding its holders.<br><br><i>ShimmerCap</i> has firsthand verified this mechanism, confirming that the postulate holds true in this transaction:<br><i><b><a target=`_blank` href='https://explorer.evm.shimmer.network/tx/0xa7904bbe43bd83c47f9d22e2a521ab73805440b716f6c0a0fcf49795ee7c6a0c'>- Link to EVM Swap Transaction Contract</a></b></i><br>And this occurs as a result of a modification of the ERC20 standart <i>_transfer()</i> function in the <i>Squid Token Contract</i>. Please refer to line 801 of the contract:<br><i><b><a target=`_blank` href='https://explorer.evm.shimmer.network/token/0x32D5b7f003b679E387bD986a89232cccC6996b54?tab=contact_code'>- Link to Code Contract</a></b></i><br><br><i>Grumpy Squid Project</i> promises on its website other future internal projects such as a series of Games and NFT collections.<br><br>The Creators/Developers of <i>Grumpy Squid Project</i> are not public knowledge (as of this writing).",
      OfficialLinks: {
        website: "https://www.grumpysquid.com",
        tokenPage: "https://www.grumpysquid.com/about-4",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/grumpy__squid",
        discord: "https://discord.com/invite/JUU7GsGR3H",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x212a4f520704c5cec17bf6c0df3f79581c048cbd",
        },
      ],
    },
  },
  {
    Address: "0xfce65b5e8efc93765fb5755d1358216d3557bb2f",
    ImageURL: "/assetLogos/inu.png",
    Name: "PathInu Token",
    Symbol: "INU",
    Details: {
      Description:
        "<b>PathInu</b> or <b>INU</b> is a <i>memecoin</i> Token, it’s emblem figure is a Chihuahua dog.<br><br>The contract's ownership has been renounced, which means, among other aspects, that no more tokens can be minted beyond the current supply.<br><br>The Creators/Developers of <b>INU</b> Token are not public knowledge (as of this writing).",
      OfficialLinks: {
        website: "https://www.pathinu.io",
      },
      SocialLinks: {
        discord: "https://discord.com/invite/CVAGKw2MVc",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0x82327f7c86bf16436e33a406b57b8a15d3ab7f7b",
        },
      ],
    },
  },
  {
    Address: "0xFaBFbc185425dC87E5871D4E8705C5536991fd9a",
    ImageURL: "/assetLogos/holdhamster.png",
    Name: "HOLD Hamster Token",
    Symbol: "HOLDHamster",
    Details: {
      Description:
        '<b>HOLDHamster</b> is a <i>memecoin</i>  Token, it’s emblem figure is a <b>cartoon Hamster</b>.<br><br>The project self-identifies as the <i>"1st market-related storytelling meme token on Shimmer EVM”</i>. This approach establishes a storyline centered around the main character of this project. As market events progress, the decisions made by The Hamster in its narrative will be influenced by the challenges and situations encountered in the market.<br><br>The creators/developers of HOLDHamster Token do not make themselves public on their website (at the time of writing this article). Although, through indirect references (Discord Roles), it’s believed that the Discord user `<i>LECTOR 02</i> <i>(@lector02)</i>` is the developer or one of the developers of the project.',
      OfficialLinks: {
        website: "https://hodlhamster.info/",
        tokenPage: "https://hodlhamster.info/",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/HODL_Hamster",
        discord: "https://discord.com/invite/WeBSqKVGDM",
      },
      Markets: [
        {
          exchange: "ShimmerSea",
          assets: ["SMR"],
          type: "DEX Pool",
          address: "0xed702da35f5340c918335d235a358d12aed8d517",
        },
      ],
    },
    isNew: true,
  },
];
